<template>
  <div class="login-wrap">
    <div class="i-login-back-head" style="justify-content: center;">
      <span class="i-back-head-text">绑定手机</span>
    </div>
    <div class="login-content">
        <div class="ant-row ant-form-item ant-form-item-has-success">
          <div class="ant-col ant-form-item-control">
            <div class="ant-form-item-control-input">
              <div class="ant-form-item-control-input-content">
                <div class="phone-input">
                  <input
                    v-model="phone"
                    class="ant-input i-input"
                    placeholder="手机号"
                    type="text"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ant-row ant-form-item">
          <div class="ant-col ant-form-item-control">
            <div class="ant-form-item-control-input">
              <div class="ant-form-item-control-input-content">
                <div style="position: relative">
                  <input
                    v-model="smscode"
                    class="ant-input i-input"
                    placeholder="验证码"
                    maxlength="10"
                    type="text"/>
                  <button
                      @click="sendSmsCode"
                      type="button"
                      class="ant-btn ant-btn-link"
                      style="position: absolute;right: 0px;top: 0px;height: 44px;" 
                      :disabled="downCount > 0">
                      <span v-if="downCount > 0">{{downCount}}s 后重发</span>
                      <span v-else>获取验证码</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ant-row ant-form-item" style="padding-top: 28px">
          <div class="ant-col ant-form-item-control">
            <div class="ant-form-item-control-input">
              <div class="ant-form-item-control-input-content">
                <div class="flex-between">
                  <button
                    @click="bindAccount"
                    class="ant-btn i-button button-red"
                    style="width: 100%">
                    <span>绑 定</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import {setUserInfo,getTempCacheData} from '@/utils';

export default {
  name: "bindAccount",
  data() {
    return {
       phone: '',
       smscode: '',
       downCount: 0,
       token: '',
       smstype: 4
    }
  },
  created(){
    let token = this.$route.query.token;
    if(token){
        getTempCacheData('TEMP_TOKEN',{token: token});
    }else {
        let cacheData = getTempCacheData('TEMP_TOKEN');
        if(cacheData){
            token = cacheData.token;
        }
    }
    this.token = token;
  },
  methods: {
    sendSmsCode(){
      this.$http.post('/api/sendSmsCode',{phone: this.phone,smstype: this.smstype}).then(()=>{
        this.$Message.success("验证码发送成功");
        clearTimeout(this.downCountTimeOut);
        this.downCount = 60;
        this.startDownCount();
      });
    },
    startDownCount(){
      this.downCountTimeOut = setTimeout(() => {
         if(this.downCount > 0){
            this.downCount --;
            this.startDownCount();
         }
      }, 1000);
    },
    bindAccount(){
      this.$http.post('/api/bindAccount',{username: this.phone,smscode: this.smscode,smstype: this.smstype,token: this.token}).then(data=>{
        setUserInfo(data);
        this.$router.push({name: 'account'});
      });
    }
  }
};
</script>
<style scoped>
.bindAccount {
  width: 100%;
  height: 100%;
  background: #f7f9fc;
}
</style>